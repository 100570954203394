function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      {...props}
    >
      <path
        d="M6.33333 12.9775V2.97754M6.33333 12.9775C6.33333 14.0821 5.5374 14.9775 4.55556 14.9775H2.77778C1.79594 14.9775 1 14.0821 1 12.9775V2.97754C1 1.87297 1.79594 0.977539 2.77778 0.977539H4.55556C5.5374 0.977539 6.33333 1.87297 6.33333 2.97754M6.33333 12.9775C6.33333 14.0821 7.12927 14.9775 8.11111 14.9775H9.88889C10.8707 14.9775 11.6667 14.0821 11.6667 12.9775M6.33333 2.97754C6.33333 1.87297 7.12927 0.977539 8.11111 0.977539H9.88889C10.8707 0.977539 11.6667 1.87297 11.6667 2.97754M11.6667 12.9775V2.97754M11.6667 12.9775C11.6667 14.0821 12.4626 14.9775 13.4444 14.9775H15.2222C16.2041 14.9775 17 14.0821 17 12.9775V2.97754C17 1.87297 16.2041 0.977539 15.2222 0.977539H13.4444C12.4626 0.977539 11.6667 1.87297 11.6667 2.97754"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
