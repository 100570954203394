function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M15.2222 8.11111H2.77778M15.2222 8.11111C16.2041 8.11111 17 8.90705 17 9.88889V15.2222C17 16.2041 16.2041 17 15.2222 17H2.77778C1.79594 17 1 16.2041 1 15.2222V9.88889C1 8.90705 1.79594 8.11111 2.77778 8.11111M15.2222 8.11111V6.33333C15.2222 5.35149 14.4263 4.55556 13.4444 4.55556M2.77778 8.11111V6.33333C2.77778 5.35149 3.57372 4.55556 4.55556 4.55556M4.55556 4.55556V2.77778C4.55556 1.79594 5.35149 1 6.33333 1H11.6667C12.6485 1 13.4444 1.79594 13.4444 2.77778V4.55556M4.55556 4.55556H13.4444"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
