/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import Button from './Button';
import { observer, inject } from 'mobx-react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Box, Checkbox, IconButton, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  SparklesIcon,
  StarIcon,
  ChatBubbleBottomCenterTextIcon,
  PhotoIcon,
  GlobeAltIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import ModelsIcon from '../Theme/icons/modelsIcon';
import CapabilitiesIcon from '../Theme/icons/capabilitiesIcon';
import { icons as modelIcons } from '../Theme/icons/modelsIcons/index';
import LLMIcon from '../Theme/icons/LLMIcon';
// import CapabilitiesIcon from '../Theme/icons/CapabilitiesIcon';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { styled as custom } from '@mui/material/styles';

import * as outlineIcons from '@heroicons/react/24/outline';
import { get } from 'lodash';
import useThemeStore from 'Theme/store';
import useChatStore from 'Components/Chat/store';

const MAX_MODELS_SELECTION = 4;

const ModelDialog = inject('store')(
  observer(
    ({
      store,
      selectedModels,
      onInteraction,
      onClose,
      onCapabilities,
      selectedCapabilities,
    }) => {
      const [modelsSelected, setModelsSelected] = useState([]);
      const [newModelsSelected, setNewModelsSelected] = useState([]);
      const [models, setModels] = useState([]);

      const [freePlanModels, setFreePlanModels] = useState([]);

      const [tabSelected, setTabSelected] = useState('0');

      const [capabilitiesSelected, setCapabilitiesSelected] = useState([]);
      const [capabilities, setCapabilities] = useState([]);

      const setModelsList = useChatStore((state) => state.setModelsList);
      const addResponseModels = useChatStore(
        (state) => state.addResponseModels
      );

      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('md'));
      const isSmallMobile = useMediaQuery(theme.breakpoints.down('sm'));

      const navigate = useNavigate();

      useEffect(() => {
        (async () => {
          await getModels();
          await getCapabilities();
        })();
      }, []);

      const getModels = async () => {
        try {
          const response = await store.api.get(`/model`);
          let availableModels = response.data.models;
          if (store.profile.plan == 'freeTos') {
            const res = await store.api.get('/user/constant', {
              params: {
                type: 'princing_plans',
              },
            });
            let freePlanModels = []; // Array to store models allowed for free users

            freePlanModels = res.data.rules[0].models;

            const modelsInFreePlan = []; // Array to store models available for free users
            const otherModels = []; // Array to store models not available for free users

            availableModels.forEach((model) => {
              if (freePlanModels.includes(model.model)) {
                modelsInFreePlan.push(model);
              } else {
                otherModels.push(model);
              }
            });
            // setModels(setDefaultModel(otherModels));
            setModels(otherModels);
            setModelsList(otherModels);
            setFreePlanModels(setDefaultModel(modelsInFreePlan));
          } else {
            setModels(setDefaultModel(response.data.models));
            setModelsList(setDefaultModel(response.data.models));
          }
        } catch (error) {
          console.error(error);
        }
      };

      const getCapabilities = async () => {
        if (selectedCapabilities) {
          setCapabilitiesSelected(selectedCapabilities);
        }
        try {
          const response = await store.api.get(`/capability`);
          setCapabilities(response.data.capabilities);
        } catch (error) {
          console.error(error);
        }
      };

      const setDefaultModel = (models) => {
        setModelsSelected(selectedModels);
        return models;
      };

      const handleChange = (model) => {
        if (addResponseModels) {
          handleNewModels(model);
          return;
        }

        if (isSelected(model)) {
          setModelsSelected((prev) =>
            prev.filter((item) => item._id !== model._id)
          );
        } else {
          if (modelsSelected.length < MAX_MODELS_SELECTION) {
            setModelsSelected((prev) => [...prev, model]);
          } else {
            toast('You only can select up to 4 LLMs simultaneously', {
              icon: '⚠️',
            });
          }
        }
      };

      const handleNewModels = (model) => {
        if (isSelected(model)) {
          return;
        }

        if (isNewSelected(model)) {
          setNewModelsSelected((prev) =>
            prev.filter((item) => item._id !== model._id)
          );
        } else {
          if (
            modelsSelected.length + newModelsSelected.length <
            MAX_MODELS_SELECTION
          ) {
            setNewModelsSelected((prev) => [...prev, model]);
          } else {
            toast('You only can select up to 4 LLMs simultaneously', {
              icon: '⚠️',
            });
          }
        }
      };

      const handleCapabilities = (capability) => {
        if (isActive(capability)) {
          setCapabilitiesSelected((prev) =>
            prev.filter((item) => item._id !== capability._id)
          );
        } else {
          setCapabilitiesSelected((prev) => [...prev, capability]);
        }
      };

      const isSelected = (model) => {
        return Boolean(modelsSelected.find((item) => item._id === model._id));
      };

      const isNewSelected = (model) => {
        return Boolean(
          newModelsSelected.find((item) => item._id === model._id)
        );
      };

      const selected = (model) => {
        return isSelected(model) || isNewSelected(model);
      };

      const disabled = (model) => {
        return addResponseModels && isSelected(model);
      };

      const isActive = (capability) => {
        return Boolean(
          capabilitiesSelected.find((item) => item._id === capability._id)
        );
      };

      const getIcon = (icon) => {
        const Icon = get(outlineIcons, icon);
        return Icon ? <Icon className={'h-6 w-6'} /> : null;
      };

      const getCapabilityIcon = (model) => {
        if (model.function_calls.length) {
          // find capability
          let modelCapabilities = capabilities.filter((capability) =>
            model.function_calls
              .map((func) => func.capability)
              .includes(capability._id)
          );
          return (
            <Shortcut>
              <CapabilitiesIcon
                className={`w-4 h-4 ${
                  isSelected(model) ? 'stroke-violet-blue' : 'stroke-cool-gray'
                }`}
              />
              <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-2/3 -top-full">
                <div className="relative z-50 p-2 text-sm leading-none text-raisin-black bg-lavender bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                  {modelCapabilities.map((capability, index) => (
                    <div key={index}>{capability.name}</div>
                  ))}
                </div>
              </Tooltip>
            </Shortcut>
          );
        } else {
          return '-';
        }
      };

      const getCompability = (capability) => {
        const comModels = modelsSelected.filter(
          (model) =>
            model.function_calls &&
            model.function_calls.length > 0 &&
            model.function_calls.some(
              (func) => func.capability == capability._id
            )
        );
        return (
          <>
            {comModels.map((model) => (
              <div key={model._id} className="flex gap-2 items-center">
                {getModelIcon(model.icon)}
                <div className="text-sm flex-1 line-clamp-1">
                  {model.abbreviation}
                </div>
              </div>
            ))}
          </>
        );
      };

      const getUncompability = (capability) => {
        const uncomModels = modelsSelected.filter(
          (model) =>
            !model.function_calls ||
            model.function_calls.length == 0 ||
            !model.function_calls.some(
              (func) => func.capability == capability._id
            )
        );
        return (
          <>
            {uncomModels.map((model) => (
              <div key={model._id} className="flex gap-2 items-center">
                {getModelIcon(model.icon)}
                <div className="text-sm flex-1 line-clamp-1">
                  {model.abbreviation}
                </div>
              </div>
            ))}
          </>
        );
      };

      const onSelect = () => {
        onInteraction(modelsSelected, newModelsSelected);
        onCapabilities(capabilitiesSelected);
      };

      const { theme: themeValue } = useThemeStore();

      const getModelIcon = (icon) => {
        const Icon = get(modelIcons, icon);
        return Icon ? (
          <Icon className="h-6 w-6" />
        ) : (
          <ModelsIcon className="h-6 w-6 stroke-cool-gray" />
        );
      };

      return (
        <>
          <div className="font-figtree fixed inset-x-0 inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 transition-opacity" onClick={onClose}>
              <div className="absolute inset-0 bg-raisin-black opacity-55"></div>
            </div>

            <div
              className={`relative ${
                themeValue == 'dark'
                  ? 'bg-lead text-crystal-bell'
                  : 'bg-seasalt text-raisin-black'
              }    rounded-[21px] overflow-hidden shadow-[0px_0px_30px_8px_rgba(0,0,0,0.25)] transform transition-all sm:max-w-4xl sm:w-full max-h-full`}
            >
              <div className={`p-7 h-min`}>
                {/* Close button */}
                <div className="absolute right-[6px] top-[6px] group">
                  <IconButton onClick={onClose}>
                    <XMarkIcon
                      className={`w-3 h-3 ${
                        theme == 'dark' ? 'text-quicksilver' : 'text-cool-gray'
                      }`}
                    />
                  </IconButton>
                </div>

                <div
                  className={`mb-0 ${
                    themeValue == 'dark'
                      ? 'text-crystal-bell'
                      : 'text-raisin-black'
                  } text-2xl font-bold mb-3`}
                >
                  Select prompt feature
                </div>

                <Box sx={{ width: '100%' }}>
                  <TabContext value={tabSelected}>
                    {/* Tabs */}
                    <TabList
                      onChange={(_, newValue) => setTabSelected(newValue)}
                      orientation="horizontal"
                      variant="scrollable"
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        '& .MuiTabs-indicator': { display: 'none' },
                      }}
                    >
                      <StyledTab
                        value="0"
                        label="LLM"
                        sx={{
                          fontFamily: 'Figtree',
                          fontSize: '14px',
                          fontWeight: 600,
                          paddingTop: '2px',
                          paddingBottom: '2px',
                          minHeight: '54px',
                          textTransform: 'none',
                        }}
                        icon={
                          <ModelsIcon
                            className={`mb-2 ${
                              tabSelected == '0'
                                ? 'stroke-white'
                                : 'stroke-cool-gray'
                            }`}
                          />
                        }
                        themeValue={themeValue}
                      />
                      <StyledTab
                        value="1"
                        label="Capabilities"
                        sx={{
                          fontFamily: 'Figtree',
                          fontSize: '14px',
                          fontWeight: 600,
                          paddingTop: '2px',
                          paddingBottom: '2px',
                          minHeight: '54px',
                          textTransform: 'none',
                        }}
                        icon={
                          <CapabilitiesIcon
                            className={`mb-2 ${
                              tabSelected == '1'
                                ? 'stroke-white'
                                : 'stroke-cool-gray'
                            }`}
                          />
                        }
                        themeValue={themeValue}
                      />

                      {tabSelected == '0' && (
                        <div
                          className={`flex flex-col justify-center ${
                            themeValue == 'dark'
                              ? 'text-crystal-bell'
                              : 'text-agate-violet'
                          } text-xs font-normal px-4 sm:px-6`}
                        >
                          <ul className="list-disc space-y-2">
                            <li className="ml-5">
                              <span className="-ml-1">
                                Select your default LLM for all your chats{' '}
                                <a
                                  className="font-semibold text-nue-blue underline"
                                  href="https://preview.straico.com/user-settings"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  here
                                </a>{' '}
                                or delve deeper into our LLM options{' '}
                                <a
                                  className="font-semibold text-nue-blue underline"
                                  href="https://straico.com/multimodel/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  here
                                </a>
                              </span>
                            </li>
                            <li className="ml-5 mt-1">
                              <span className="-ml-1">
                                You can select up to 4 LLMs simultaneously
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}

                      {tabSelected == '1' && (
                        <div
                          className={`flex flex-col justify-center ${
                            themeValue == 'dark'
                              ? 'text-crystal-bell'
                              : 'text-agate-violet'
                          } text-xs font-normal px-4 sm:px-6`}
                        >
                          <ul className="list-disc space-y-2">
                            <li className="ml-5">
                              <span className="py-2 pb-1 text-xs font-normal">
                                Select a capability for enhancing your LLM
                                power.
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                    </TabList>

                    {/* Panel 0 */}
                    <TabPanel value="0" sx={{ padding: '12px 0 0 0' }}>
                      {/* This is the models for the free plan */}
                      {freePlanModels.length > 0 && (
                        <div>
                          <div
                            id="straico-llm-list"
                            className="max-h-96 overflow-y-hidden"
                          >
                            {freePlanModels.map((model, index) => (
                              <div
                                key={index}
                                onClick={() => {
                                  handleChange(model);
                                  setFreePlanModels((prevModels) => {
                                    const modified = prevModels.map((item) => {
                                      item.selected = item._id === model._id;
                                      return item;
                                    });
                                    return modified;
                                  });
                                }}
                              >
                                <div></div>
                                {index == 0 ? (
                                  <div
                                    className={`grid grid-cols-[${
                                      isMobile
                                        ? '2.5fr_repeat(3,0.5fr)'
                                        : '2.5fr_0.5fr_0.75fr_repeat(4,0.5fr)'
                                    }] gap-x-2 items-center justify-between w-full  p-1 rounded cursor-pointer select-none`}
                                  >
                                    <div
                                      className={`font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      }`}
                                    >
                                      Model
                                    </div>
                                    {isMobile ? (
                                      ''
                                    ) : (
                                      <div
                                        className={`font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        }`}
                                      >
                                        Max words
                                      </div>
                                    )}
                                    {isMobile ? (
                                      ''
                                    ) : (
                                      <div
                                        className={`font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        }`}
                                      >
                                        Moderation
                                      </div>
                                    )}
                                    <div
                                      className={`font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      }`}
                                    >
                                      Features
                                    </div>
                                    <div
                                      className={`font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      }`}
                                    >
                                      Capabilities
                                    </div>
                                    {isMobile ? (
                                      ''
                                    ) : (
                                      <div
                                        className={`font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        }`}
                                      >
                                        Editor's choice
                                      </div>
                                    )}
                                    <div
                                      className={`font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      }`}
                                    >
                                      Coins x 100 words
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                <div
                                  className={`grid grid-cols-[${
                                    isMobile
                                      ? '2.5fr_repeat(3,0.5fr)'
                                      : '2.5fr_0.5fr_0.75fr_repeat(4,0.5fr)'
                                  }] gap-x-2 items-center justify-between w-full ${
                                    selected(model)
                                      ? themeValue == 'dark'
                                        ? `${
                                            disabled(model)
                                              ? 'bg-palladium'
                                              : 'bg-ship-grey'
                                          } font-medium`
                                        : `${
                                            disabled(model)
                                              ? 'bg-ghost-white'
                                              : 'bg-lavender'
                                          } font-medium`
                                      : 'font-normal'
                                  } p-1 rounded ${
                                    selected(model) &&
                                    !disabled(model) &&
                                    'cursor-pointer'
                                  } select-none`}
                                >
                                  <div
                                    className={`flex  items-center w-full text-sm font-semibold ${
                                      themeValue == 'dark'
                                        ? 'text-white'
                                        : 'text-black'
                                    }`}
                                  >
                                    <div className="flex gap-2">
                                      {getModelIcon(model.icon)}
                                      <div
                                        className={` whitespace-nowrap truncate ${
                                          isSmallMobile ? 'max-w-[180px]' : ''
                                        }`}
                                      >
                                        {model.name}
                                      </div>
                                    </div>
                                  </div>
                                  {isMobile ? (
                                    ''
                                  ) : (
                                    <div
                                      className={` w-full  text-xs ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      }`}
                                    >
                                      <div className="text-xs font-normal">
                                        ~{' '}
                                        {model.words_text.toLocaleString(
                                          'en-US'
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {isMobile ? (
                                    ''
                                  ) : (
                                    <div
                                      className={`text-xs ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      } font-normal`}
                                    >
                                      <div>{model.moderation}</div>
                                    </div>
                                  )}
                                  <div
                                    className={`w-full text-xs t${
                                      themeValue == 'dark'
                                        ? 'text-white'
                                        : 'text-black'
                                    }`}
                                  >
                                    <div
                                      className={`relative flex gap-x-1 ${
                                        isSelected(model)
                                          ? 'text-violet-blue '
                                          : ' text-cool-gray '
                                      }`}
                                    >
                                      <Shortcut>
                                        <ChatBubbleBottomCenterTextIcon className="w-4 h-4" />
                                        <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                          <span
                                            className={`relative z-50 p-2 text-sm leading-none ${
                                              themeValue == 'dark'
                                                ? 'text-white'
                                                : 'text-black'
                                            } bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md`}
                                          >
                                            Text input/output enabled
                                          </span>
                                        </Tooltip>
                                      </Shortcut>
                                      {model.type == 'vision' && (
                                        <Shortcut>
                                          <PhotoIcon className="w-4 h-4" />
                                          <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                            <span
                                              className={`relative z-50 p-2 text-sm leading-none ${
                                                themeValue == 'dark'
                                                  ? 'text-white'
                                                  : 'text-black'
                                              } bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md`}
                                            >
                                              Accepts image input
                                            </span>
                                          </Tooltip>
                                        </Shortcut>
                                      )}
                                      {model.type == 'web' && (
                                        <Shortcut>
                                          <GlobeAltIcon className="w-4 h-4" />
                                          <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                            <span
                                              className={`relative z-50 p-2 text-sm leading-none ${
                                                themeValue == 'dark'
                                                  ? 'text-white'
                                                  : 'text-black'
                                              } bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md`}
                                            >
                                              Web search capable
                                            </span>
                                          </Tooltip>
                                        </Shortcut>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className={`w-full  text-xs ${
                                      themeValue == 'dark'
                                        ? 'text-white'
                                        : 'text-black'
                                    }`}
                                  >
                                    <div
                                      className={`relative flex justify-center ${
                                        isSelected(model)
                                          ? 'text-violet-blue '
                                          : ' text-cool-gray '
                                      }`}
                                    >
                                      {getCapabilityIcon(model)}
                                    </div>
                                  </div>
                                  {isMobile ? (
                                    ''
                                  ) : !model.editors_link ? (
                                    <div className="text-center">
                                      <p>-</p>
                                    </div>
                                  ) : (
                                    <div
                                      className={`text-xs ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      } font-normal text-center`}
                                    >
                                      <a
                                        href={model.editors_link}
                                        className="text-sm"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <p>👑</p>
                                      </a>
                                    </div>
                                  )}
                                  <div className="w-full text-xs">
                                    <div
                                      className={`text-xs font-bold ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      } flex gap-1`}
                                    >
                                      <CurrencyDollarIcon
                                        className={`w-4 h-4 ${
                                          isSelected(model)
                                            ? 'text-violet-blue '
                                            : ' text-cool-gray '
                                        }`}
                                      />
                                      <div>{model.pricing.coins}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="flex justify-end w-full pt-2">
                              <Button
                                title={'Select'}
                                disabled={!modelsSelected.length}
                                noMarginTop
                                onClick={onSelect}
                                Icon={CheckCircleIcon}
                                iconSize="w-[18px] h-[18px]"
                                customRound="rounded-xl"
                                customPaddig="px-4 py-2"
                                customWeight="font-semibold"
                                noUppercase
                              />
                            </div>
                          </div>
                          <div className="relative max-h-96 min-h-80">
                            {models.length > 0 && (
                              <div
                                id="straico-llm-list"
                                className="max-h-96 overflow-y-hidden"
                              >
                                {store.profile.plan == 'freeTos' && (
                                  <div className="absolute inset-0 z-0 justify-center h-auto m-auto flex flex-col items-center px-20">
                                    <SparklesIcon className="h-6 flex justify-center w-full mb-5" />
                                    <div
                                      className={`text-center not-italic ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      } text-sm font-bold uppercase pb-3`}
                                    >
                                      {store.profile.trial_end
                                        ? 'Want to unlock the full potential?'
                                        : 'Want access to all our LLMs (ai models)?'}
                                    </div>{' '}
                                    {store.profile.trial_end ? (
                                      <div
                                        className={`text-center not-italic ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        } text-sm font-medium pb-5`}
                                      >
                                        <a
                                          href="/my-profile/pricing"
                                          className="text-violet-blue text-sm font-semibold underline"
                                        >
                                          Upgrade now
                                        </a>{' '}
                                        to access multi-model feature and{' '}
                                        {models.length} different filtered and
                                        unfiltered LLMs to query.
                                      </div>
                                    ) : (
                                      <div
                                        className={`text-center not-italic ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        } text-sm  font-medium pb-5`}
                                      >
                                        Interact with {models.length} different
                                        LLMs on our platform. Start your{' '}
                                        <a
                                          href="/my-profile/pricing"
                                          className="text-violet-blue text-sm font-semibold underline"
                                        >
                                          {
                                            process.env
                                              .REACT_APP_TRIAL_PERIOD_DAYS
                                          }
                                          -day trial{' '}
                                        </a>{' '}
                                        for just $1 now!
                                      </div>
                                    )}
                                    <div className="flex justify-center w-full">
                                      <button
                                        type="button"
                                        className="bg-nue-blue shadow-md rounded py-1 px-3 text-white font-bold text-center text-sm uppercase flex"
                                        onClick={() => {
                                          navigate('/my-profile/pricing');
                                        }}
                                      >
                                        <StarIcon className="w-5 h-5  mr-2" />
                                        {store.profile.trial_end
                                          ? 'Upgrade Today'
                                          : `try for ${process.env.REACT_APP_TRIAL_PERIOD_DAYS} days for $1`}
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <div className=" absolute inset-0 z-[-1] opacity-20 blur-[1px] pt-2">
                                  {models.map((model, index) => (
                                    <div
                                      key={index}
                                      onClick={() => {
                                        handleChange(model);
                                        setModels((prevModels) => {
                                          const modified = prevModels.map(
                                            (item) => {
                                              item.selected =
                                                item._id === model._id;
                                              return item;
                                            }
                                          );
                                          return modified;
                                        });
                                      }}
                                    >
                                      <div
                                        className={`grid grid-cols-[${
                                          isMobile
                                            ? '2.5fr_repeat(2,0.5fr)'
                                            : '2.5fr_0.5fr_0.75fr_repeat(3,0.5fr)'
                                        }] gap-x-2 items-center justify-between w-full ${
                                          selected(model)
                                            ? themeValue == 'dark'
                                              ? `${
                                                  disabled(model)
                                                    ? 'bg-palladium'
                                                    : 'bg-ship-grey'
                                                } font-medium`
                                              : `${
                                                  disabled(model)
                                                    ? 'bg-ghost-white'
                                                    : 'bg-lavender'
                                                } font-medium`
                                            : 'font-normal'
                                        } p-1 rounded ${
                                          selected(model) &&
                                          !disabled(model) &&
                                          'cursor-pointer'
                                        } select-none`}
                                      >
                                        <div
                                          className={`flex  items-center w-full text-sm font-semibold ${
                                            themeValue == 'dark'
                                              ? 'text-white'
                                              : 'text-black'
                                          }`}
                                        >
                                          <div className="flex gap-2">
                                            {getModelIcon(model.icon)}
                                            <div
                                              className={` whitespace-nowrap truncate ${
                                                isSmallMobile
                                                  ? 'max-w-[180px]'
                                                  : ''
                                              }`}
                                            >
                                              {model.name}
                                            </div>
                                          </div>
                                        </div>
                                        {isMobile ? (
                                          ''
                                        ) : (
                                          <div
                                            className={` w-full  text-xs ${
                                              themeValue == 'dark'
                                                ? 'text-white'
                                                : 'text-black'
                                            }`}
                                          >
                                            <div
                                              className={`text-xs ${
                                                themeValue == 'dark'
                                                  ? 'text-white'
                                                  : 'text-black'
                                              } font-normal`}
                                            >
                                              ~{' '}
                                              {model.words_text.toLocaleString(
                                                'en-US'
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {isMobile ? (
                                          ''
                                        ) : (
                                          <div
                                            className={`text-xs ${
                                              themeValue == 'dark'
                                                ? 'text-white'
                                                : 'text-black'
                                            } font-normal`}
                                          >
                                            <div>{model.moderation}</div>
                                          </div>
                                        )}
                                        <div
                                          className={`w-full  text-xs text-black dark:text-white`}
                                        >
                                          <div
                                            className={`relative flex gap-x-1 ${
                                              isSelected(model)
                                                ? 'text-violet-blue '
                                                : ' text-cool-gray '
                                            }`}
                                          >
                                            <Shortcut>
                                              <ChatBubbleBottomCenterTextIcon className="w-4 h-4" />
                                              <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                                <span className="relative z-50 p-2 text-sm leading-none text-black bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                                  Text input/output enabled
                                                </span>
                                              </Tooltip>
                                            </Shortcut>
                                            {model.type == 'vision' && (
                                              <Shortcut>
                                                <PhotoIcon className="w-4 h-4" />
                                                <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                                  <span className="relative z-50 p-2 text-sm leading-none text-black bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                                    Accepts image input
                                                  </span>
                                                </Tooltip>
                                              </Shortcut>
                                            )}
                                            {model.type == 'web' && (
                                              <Shortcut>
                                                <GlobeAltIcon className="w-4 h-4" />
                                                <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                                  <span className="relative z-50 p-2 text-sm leading-none text-black bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                                    Web search capable
                                                  </span>
                                                </Tooltip>
                                              </Shortcut>
                                            )}
                                          </div>
                                        </div>
                                        <div className="w-full text-xs">
                                          <div
                                            className={`text-xs font-bold flex gap-1 ${
                                              themeValue == 'dark'
                                                ? 'text-white'
                                                : 'text-black'
                                            }`}
                                          >
                                            <CurrencyDollarIcon
                                              className={`w-4 h-4 ${
                                                isSelected(model)
                                                  ? 'text-violet-blue '
                                                  : ' text-cool-gray '
                                              }`}
                                            />
                                            <div>{model.pricing.coins}</div>
                                          </div>
                                        </div>
                                        {isMobile ? (
                                          ''
                                        ) : !model.editors_link ? (
                                          <div className="text-center">
                                            <p>-</p>
                                          </div>
                                        ) : (
                                          <div className="text-xs text-black dark:text-white font-normal text-center">
                                            <a
                                              href={model.editors_link}
                                              className="text-sm"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <p>👑</p>
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {models.length > 0 &&
                        store.profile.plan !== 'freeTos' && (
                          <>
                            <div
                              id="straico-llm-list"
                              className={`max-h-96 overflow-y-scroll overflow-rtl ${
                                themeValue == 'dark'
                                  ? 'overflow-rtl-dark'
                                  : 'overflow-rtl-light'
                              }`}
                            >
                              {models.map((model, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    handleChange(model);
                                    setModels((prevModels) => {
                                      const modified = prevModels.map(
                                        (item) => {
                                          item.selected =
                                            item._id === model._id;
                                          return item;
                                        }
                                      );
                                      return modified;
                                    });
                                  }}
                                >
                                  {index == 0 ? (
                                    <div
                                      className={`grid grid-cols-[${
                                        isMobile
                                          ? '2.5fr_repeat(3,0.5fr)'
                                          : '2.5fr_0.5fr_0.75fr_repeat(4,0.5fr)'
                                      }] gap-x-2 items-center justify-between w-full  p-1 rounded cursor-pointer select-none`}
                                    >
                                      <div
                                        className={`font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        }`}
                                      >
                                        Model
                                      </div>
                                      {isMobile ? (
                                        ''
                                      ) : (
                                        <div
                                          className={`font-bold text-xs ${
                                            themeValue == 'dark'
                                              ? 'text-white'
                                              : 'text-black'
                                          }`}
                                        >
                                          Max words
                                        </div>
                                      )}
                                      {isMobile ? (
                                        ''
                                      ) : (
                                        <div
                                          className={`font-bold text-xs ${
                                            themeValue == 'dark'
                                              ? 'text-white'
                                              : 'text-black'
                                          }`}
                                        >
                                          Moderation
                                        </div>
                                      )}
                                      <div
                                        className={`font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        }`}
                                      >
                                        Features
                                      </div>
                                      <div
                                        className={`font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        }`}
                                      >
                                        Capabilities
                                      </div>
                                      {isMobile ? (
                                        ''
                                      ) : (
                                        <div
                                          className={`font-bold text-xs ${
                                            themeValue == 'dark'
                                              ? 'text-white'
                                              : 'text-black'
                                          }`}
                                        >
                                          Editor's choice
                                        </div>
                                      )}
                                      <div
                                        className={`font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        }`}
                                      >
                                        Coins x 100 words
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div
                                    className={`grid grid-cols-[${
                                      isMobile
                                        ? '2.5fr_repeat(3,0.5fr)'
                                        : '2.5fr_0.5fr_0.75fr_repeat(4,0.5fr)'
                                    }] gap-x-2 items-center justify-between w-full ${
                                      selected(model)
                                        ? themeValue == 'dark'
                                          ? `${
                                              disabled(model)
                                                ? 'bg-palladium'
                                                : 'bg-ship-grey'
                                            } font-medium`
                                          : `${
                                              disabled(model)
                                                ? 'bg-ghost-white'
                                                : 'bg-lavender'
                                            } font-medium`
                                        : 'font-normal'
                                    } p-1 rounded ${
                                      selected(model) &&
                                      !disabled(model) &&
                                      'cursor-pointer'
                                    } select-none`}
                                  >
                                    <div
                                      className={`flex  items-center w-full text-sm font-semibold ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      }`}
                                    >
                                      <div className="flex gap-2">
                                        {getModelIcon(model.icon)}
                                        <div
                                          className={` whitespace-nowrap truncate ${
                                            isSmallMobile ? 'max-w-[180px]' : ''
                                          }`}
                                        >
                                          {model.name}
                                        </div>
                                      </div>
                                    </div>
                                    {isMobile ? (
                                      ''
                                    ) : (
                                      <div
                                        className={` w-full  text-xs ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        }`}
                                      >
                                        <div
                                          className={`text-xs ${
                                            themeValue == 'dark'
                                              ? 'text-white'
                                              : 'text-black'
                                          } font-normal`}
                                        >
                                          ~{' '}
                                          {model.words_text.toLocaleString(
                                            'en-US'
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {isMobile ? (
                                      ''
                                    ) : (
                                      <div
                                        className={`text-xs ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        } font-normal`}
                                      >
                                        <div>{model.moderation}</div>
                                      </div>
                                    )}
                                    <div
                                      className={`w-full  text-xs ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      }`}
                                    >
                                      <div
                                        className={`relative flex gap-x-1 ${
                                          isSelected(model)
                                            ? 'text-violet-blue '
                                            : ' text-cool-gray '
                                        }`}
                                      >
                                        <Shortcut>
                                          <ChatBubbleBottomCenterTextIcon className="w-4 h-4" />
                                          <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                            <span className="relative z-50 p-2 text-sm leading-none text-black bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                              Text input/output enabled
                                            </span>
                                          </Tooltip>
                                        </Shortcut>
                                        {model.type == 'vision' && (
                                          <Shortcut>
                                            <PhotoIcon className="w-4 h-4" />
                                            <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                              <span className="relative z-50 p-2 text-sm leading-none text-black bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                                Accepts image input
                                              </span>
                                            </Tooltip>
                                          </Shortcut>
                                        )}
                                        {model.type == 'web' && (
                                          <Shortcut>
                                            <GlobeAltIcon className="w-4 h-4" />
                                            <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                              <span className="relative z-50 p-2 text-sm leading-none text-black bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                                Web search capable
                                              </span>
                                            </Tooltip>
                                          </Shortcut>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className={`w-full  text-xs text-black dark:text-white`}
                                    >
                                      <div
                                        className={`relative flex justify-center ${
                                          isSelected(model)
                                            ? 'text-violet-blue '
                                            : ' text-cool-gray '
                                        }`}
                                      >
                                        {getCapabilityIcon(model)}
                                      </div>
                                    </div>
                                    {isMobile ? (
                                      ''
                                    ) : !model.editors_link ? (
                                      <div className="text-center">
                                        <p>-</p>
                                      </div>
                                    ) : (
                                      <div className="text-xs text-black dark:text-white font-normal text-center">
                                        <a
                                          href={model.editors_link}
                                          className="text-sm"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <p>👑</p>
                                        </a>
                                      </div>
                                    )}
                                    <div className="w-full text-xs">
                                      <div
                                        className={`text-xs font-bold flex gap-1 ${
                                          themeValue == 'dark'
                                            ? 'text-white'
                                            : 'text-black'
                                        }`}
                                      >
                                        <CurrencyDollarIcon
                                          className={`w-4 h-4 ${
                                            isSelected(model)
                                              ? 'text-violet-blue '
                                              : ' text-cool-gray '
                                          }`}
                                        />
                                        <div>{model.pricing.coins}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="flex justify-center w-full pt-2">
                              <Button
                                title={'Select'}
                                disabled={!modelsSelected.length}
                                noMarginTop
                                onClick={onSelect}
                                Icon={CheckCircleIcon}
                                iconSize="w-[18px] h-[18px]"
                                customRound="rounded-xl"
                                customPaddig="px-4 py-2"
                                customWeight="font-semibold"
                                noUppercase
                              />
                            </div>
                          </>
                        )}
                    </TabPanel>

                    {/* Panel 1 */}
                    <TabPanel value="1" sx={{ padding: '12px 0 0 0' }}>
                      <div>
                        {capabilities.map((capability, index) => (
                          <div key={index} className="text-black">
                            {index == 0 && (
                              <div
                                className={`grid ${
                                  isMobile
                                    ? 'grid-cols-[repeat(4,1fr)]'
                                    : 'grid-cols-[repeat(8,1fr)]'
                                } gap-x-2 items-center justify-between w-full  p-1 rounded select-none`}
                              >
                                <div
                                  className={`font-bold text-xs ${
                                    themeValue == 'dark'
                                      ? 'text-white'
                                      : 'text-black'
                                  } col-span-3`}
                                >
                                  Capability
                                </div>
                                {!isMobile && (
                                  <>
                                    <div
                                      className={`font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      } col-span-2`}
                                    >
                                      Compatible with selected
                                    </div>
                                    <div
                                      className={`font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'text-white'
                                          : 'text-black'
                                      } col-span-2`}
                                    >
                                      Incompatible with selected
                                    </div>
                                  </>
                                )}
                                <div
                                  className={`font-bold text-xs ${
                                    themeValue == 'dark'
                                      ? 'text-white'
                                      : 'text-black'
                                  }`}
                                >
                                  Coins per prompt
                                </div>
                              </div>
                            )}
                            <div
                              className={`grid ${
                                isMobile
                                  ? 'grid-cols-[repeat(4,1fr)]'
                                  : 'grid-cols-[repeat(8,1fr)]'
                              } gap-x-2 items-center justify-between w-full  p-1 rounded select-none`}
                            >
                              <div
                                className={`col-span-3 flex gap-2 font-semibold text-sm ${
                                  themeValue == 'dark'
                                    ? 'text-white'
                                    : 'text-black'
                                } ${
                                  isActive(capability)
                                    ? themeValue == 'dark'
                                      ? 'bg-ship-grey'
                                      : 'bg-lavender'
                                    : themeValue == 'dark'
                                    ? 'bg-night-black'
                                    : 'bg-ghost-white'
                                } rounded-xl px-3 py-4 cursor-pointer`}
                                onClick={() => handleCapabilities(capability)}
                              >
                                <div
                                  className={
                                    isActive(capability) &&
                                    (themeValue == 'dark'
                                      ? 'text-stargate-shimmer'
                                      : 'text-violet-blue')
                                  }
                                >
                                  {getIcon(capability.icon)}
                                </div>
                                <div className="flex-1">
                                  <div
                                    className={`text-base ${
                                      isActive(capability) &&
                                      (themeValue == 'dark'
                                        ? 'text-stargate-shimmer'
                                        : 'text-violet-blue')
                                    }`}
                                  >
                                    {capability.name}
                                  </div>
                                  <div className="font-normal text-sm">
                                    {capability.description}
                                  </div>
                                </div>
                              </div>
                              {!isMobile && (
                                <>
                                  <div
                                    className={`h-full text-xs ${
                                      themeValue == 'dark'
                                        ? 'text-white'
                                        : 'text-black'
                                    } col-span-2`}
                                  >
                                    {getCompability(capability)}
                                  </div>
                                  <div className="h-full text-xs text-pottery-red col-span-2">
                                    {getUncompability(capability)}
                                  </div>
                                </>
                              )}
                              <div className="w-full text-xs">
                                <div
                                  className={`text-base leading-[14px] font-normal ${
                                    themeValue == 'dark'
                                      ? 'text-white'
                                      : 'text-black'
                                  } flex gap-2 items-center`}
                                >
                                  <CurrencyDollarIcon className="w-5 h-5 text-cool-gray" />
                                  <div>{capability.coins}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="flex justify-center w-full pt-2">
                          <Button
                            title={'Select'}
                            disabled={!modelsSelected.length}
                            noMarginTop
                            onClick={onSelect}
                            Icon={CheckCircleIcon}
                            iconSize="w-[18px] h-[18px]"
                            customRound="rounded-xl"
                            customPaddig="px-4 py-2"
                            customWeight="font-semibold"
                            noUppercase
                          />
                        </div>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </>
      );
    }
  )
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;

const StyledTab = custom((props) => <Tab {...props} />)((props) => ({
  width: '102px',
  height: '58px',
  borderRadius: '12px',
  backgroundColor: props.themeValue == 'dark' ? '#3B3B45' : '#E1E1E5',
  marginRight: '4px',
  color: '#9091A4',
  '&.Mui-selected': {
    color: '#FFFFFF',
    backgroundColor: '#464BBA',
  },
}));

export default ModelDialog;
