/* eslint-disable no-console */
import { useState } from 'react';
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  Typography,
  DialogContent,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
} from '@mui/material';
import {
  ArrowUturnLeftIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline';
import {
  HandThumbUpIcon as HandThumbUpIconSolid,
  HandThumbDownIcon as HandThumbDownIconSolid,
} from '@heroicons/react/24/solid';
import { inject, observer } from 'mobx-react';
import useSmartModeStore from '../../../Features/SmartMode/store';
import useThemeStore from 'Theme/store';
import { toast } from 'react-hot-toast';

const PromptOptions = inject('store')(
  observer(
    ({
      store,
      loading,
      originalPrompt,
      optimizedPrompt,
      onUndoOptimization,
      conversationId,
      basePrompt,
      systemInstructions,
    }) => {
      const { theme: themeValue } = useThemeStore();
      const dontAskAgain = useSmartModeStore(
        (state) => state.dontAskUndoOptimization
      );
      const setDontAskAgain = useSmartModeStore(
        (state) => state.setDontAskUndoOptimization
      );

      const [selected, setSelected] = useState(null);
      const [openDialog, setOpenDialog] = useState(false);
      const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
      const [feedback, setFeedback] = useState('');
      const [revertOptimization, setRevertOptimization] = useState(false);

      const handleThumbClick = async (thumb) => {
        const newSelection = selected === thumb ? null : thumb;
        setSelected(newSelection);

        if (newSelection === 'down') {
          await sendFeedback(false);
          setRevertOptimization(false);
          setFeedback('');
          setOpenFeedbackDialog(true);
        } else if (newSelection === 'up') {
          await sendFeedback(true);
        } else {
          await sendFeedback(null);
        }
      };

      const handleFeedbackSubmit = async () => {
        if (feedback.trim() === '') {
          toast.error('Please write your feedback before sending');
          return;
        }

        await sendFeedback(false, revertOptimization, feedback);
        setOpenFeedbackDialog(false);
        setRevertOptimization(false);
        setFeedback('');

        toast.success('Thank you for your feedback!');

        if (revertOptimization && onUndoOptimization) {
          onUndoOptimization();
        }
      };

      const [undoTriggered, setUndoTriggered] = useState(false);

      const handleConfirm = async () => {
        if (revertOptimization) {
          setDontAskAgain(true);
        }
        setOpenDialog(false);
        await sendFeedback(null, true);

        if (onUndoOptimization) {
          onUndoOptimization();
        }

        setUndoTriggered((prev) => !prev);
      };

      const sendFeedback = async (liked, isUndo = false, comments = '') => {
        try {
          await store.api.post('/ai/user-feedback', {
            is_undo: isUndo,
            liked,
            comments,
            original_prompt: originalPrompt,
            optimized_prompt: optimizedPrompt,
            conversationId,
            base_prompt: basePrompt,
            system_instructions: systemInstructions,
          });
        } catch (error) {
          console.error('Error sending feedback:', error);
        }
      };

      return (
        <div
          className={`flex items-center rounded-md shadow-sm ${
            themeValue === 'dark'
              ? 'text-gray-100 text-opacity-70'
              : 'text-gray-600 text-opacity-70'
          }`}
          style={{
            backgroundColor: themeValue === 'dark' ? '#39383A' : '#EDEDF2',
          }}
        >
          {/* Left-aligned icons */}
          <div className="flex items-center">
            <Tooltip title="Good Optimization" arrow>
              <IconButton
                size="small"
                onClick={() => {
                  if (loading) {
                    toast.error(
                      'Please wait for the model to finish responding before performing this action.'
                    );
                  } else {
                    handleThumbClick('up');
                  }
                }}
              >
                {selected === 'up' ? (
                  <HandThumbUpIconSolid
                    className={`h-4 w-4 ${
                      themeValue === 'dark'
                        ? 'purple-icon-color-dark'
                        : 'purple-icon-color-light'
                    }`}
                  />
                ) : (
                  <HandThumbUpIcon className="h-4 w-4 text-battleship-gray" />
                )}
              </IconButton>
            </Tooltip>
            {/* Thumb Down Button */}
            <Tooltip title="Bad Optimization" arrow>
              <IconButton
                size="small"
                onClick={() => {
                  if (loading) {
                    toast.error(
                      'Please wait for the model to finish responding before performing this action.'
                    );
                  } else {
                    handleThumbClick('down');
                  }
                }}
              >
                {selected === 'down' ? (
                  <HandThumbDownIconSolid
                    className={`h-4 w-4 ${
                      themeValue === 'dark'
                        ? 'purple-icon-color-dark'
                        : 'purple-icon-color-light'
                    }`}
                  />
                ) : (
                  <HandThumbDownIcon className="h-4 w-4 text-battleship-gray" />
                )}
              </IconButton>
            </Tooltip>
          </div>

          {/* Undo Optimization */}
          <Tooltip title="Undo Optimization" arrow>
            <IconButton
              size="small"
              onClick={() => {
                if (loading) {
                  toast.error(
                    'Please wait for the model to finish responding before performing this action.'
                  );
                } else {
                  if (dontAskAgain) {
                    handleConfirm();
                  } else {
                    setOpenDialog(true);
                  }
                }
              }}
            >
              <ArrowUturnLeftIcon
                className={`h-4 w-4 ${
                  themeValue === 'dark'
                    ? 'purple-icon-color-dark'
                    : 'purple-icon-color-light'
                }`}
              />
            </IconButton>
          </Tooltip>

          {/* Confirmation Dialog */}
          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            PaperProps={{
              sx: {
                borderRadius: '16px',
                padding: '16px',
                bgcolor: themeValue === 'dark' ? '#252425' : '#F7F7F8',
                width: '400px',
              },
            }}
          >
            <DialogContent className="font-figtree">
              <Typography
                sx={{
                  marginBottom: '4px',
                  color: themeValue === 'dark' ? '#cfcccc' : '#6e6e6e',
                  fontFamily: 'Figtree, sans-serif',
                }}
              >
                Are you sure you want to undo the prompt optimization?
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={revertOptimization}
                    onChange={(e) => setRevertOptimization(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      color: themeValue === 'dark' ? '#cfcccc' : '#6e6e6e',
                      fontFamily: 'Figtree, sans-serif',
                    }}
                  >
                    Don't ask me again
                  </Typography>
                }
              />
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'space-between' }}>
              <Button
                onClick={() => setOpenDialog(false)}
                variant="outlined"
                sx={{
                  borderRadius: '14px',
                  fontWeight: 'bold',
                  fontSize: '0.9rem',
                  color: themeValue === 'dark' ? '#7F82C3' : '#9091A4',
                  backgroundColor:
                    themeValue === 'dark' ? '#3B3B45' : '#E1E1E5',
                  borderColor: 'transparent',
                  '&:hover': {
                    borderColor: 'transparent',
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '14px',
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
                Proceed
              </Button>
            </DialogActions>
          </Dialog>
          {/* Feedback Dialog */}
          <Dialog
            open={openFeedbackDialog}
            onClose={() => setOpenFeedbackDialog(false)}
            PaperProps={{
              sx: {
                borderRadius: '16px',
                padding: '16px',
                bgcolor: themeValue === 'dark' ? '#252425' : '#F7F7F8',
                fontFamily: 'Figtree, sans-serif',
              },
            }}
          >
            <DialogContent>
              <Typography
                variant="h5"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  color: themeValue === 'dark' ? '#D4D5E6' : 'black',
                  mb: 2,
                  fontFamily: 'Figtree, sans-serif',
                }}
              >
                Woops!
              </Typography>
              <Typography
                sx={{
                  marginBottom: '4px',
                  color: themeValue === 'dark' ? '#cfcccc' : '#6e6e6e',
                  fontFamily: 'Figtree, sans-serif',
                }}
              >
                We are sorry to hear that our optimization was not correct,
                could you share why you did not like the optimization?
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Your feedback..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                variant="outlined"
                sx={{
                  marginTop: '16px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    resize: 'vertical',
                    overflow: 'auto',
                    bgcolor: themeValue === 'dark' ? '#333333' : '#FFFFFF',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: themeValue === 'dark' ? '#787878' : '#D0D0D8',
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '0.875rem',
                    color: themeValue === 'dark' ? '#ffffff' : '#000000',
                    fontFamily: 'Figtree, sans-serif',
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={revertOptimization}
                    onChange={(e) => setRevertOptimization(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      color: themeValue === 'dark' ? '#cfcccc' : '#6e6e6e',
                      fontFamily: 'Figtree, sans-serif',
                    }}
                  >
                    Undo Optimization Too
                  </Typography>
                }
              />
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'space-between' }}>
              <Button
                onClick={async () => {
                  if (revertOptimization) {
                    await sendFeedback(false, true, feedback);
                    if (onUndoOptimization) {
                      onUndoOptimization();
                    }
                  } else if (feedback.trim() !== '') {
                    await sendFeedback(false, false, feedback);
                  }
                  setOpenFeedbackDialog(false);
                  setRevertOptimization(false);
                  setFeedback('');
                }}
                variant="outlined"
                sx={{
                  borderRadius: '14px',
                  fontWeight: 'bold',
                  fontSize: '0.9rem',
                  color: themeValue === 'dark' ? '#7F82C3' : '#9091A4',
                  backgroundColor:
                    themeValue === 'dark' ? '#3B3B45' : '#E1E1E5',
                  borderColor: 'transparent',
                  '&:hover': {
                    borderColor: 'transparent',
                  },
                }}
              >
                No thanks!
              </Button>
              <Button
                onClick={handleFeedbackSubmit}
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '14px',
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
                Send Feedback
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  )
);

export default PromptOptions;
