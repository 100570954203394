function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M1 2.88662C1 1.65654 1.679 0.977539 2.90909 0.977539H6.09089C7.32098 0.977539 7.99998 1.65654 7.99998 2.88662V6.06843C7.99998 7.29852 7.32098 7.97752 6.09089 7.97752H2.90909C1.679 7.97752 1 7.29852 1 6.06843V2.88662Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2.88662C8 1.65654 8.679 0.977539 9.90909 0.977539H13.0909C14.321 0.977539 15 1.65654 15 2.88662V6.06843C15 7.29852 14.321 7.97752 13.0909 7.97752H9.90909C8.679 7.97752 8 7.29852 8 6.06843V2.88662Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 9.93252C1 8.70244 1.679 8.02344 2.90909 8.02344H6.09089C7.32098 8.02344 7.99998 8.70244 7.99998 9.93252V13.1143C7.99998 14.3444 7.32098 15.0234 6.09089 15.0234H2.90909C1.679 15.0234 1 14.3444 1 13.1143V9.93252Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9.93252C8 8.70244 8.679 8.02344 9.90909 8.02344H13.0909C14.321 8.02344 15 8.70244 15 9.93252V13.1143C15 14.3444 14.321 15.0234 13.0909 15.0234H9.90909C8.679 15.0234 8 14.3444 8 13.1143V9.93252Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
