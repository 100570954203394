/* eslint-disable no-console */
import {
  AtSymbolIcon,
  BookmarkIcon,
  CalculatorIcon,
  IdentificationIcon,
  KeyIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline';
import {
  ClipboardIcon,
  PencilSquareIcon,
  UserCircleIcon,
} from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { RefreshOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Container } from '@mui/material';
import BackButton from '@straico-ui/commons/BackButton';
import LabelIcon from '@straico-ui/forms/LabelIcon';
import TextInput from '@straico-ui/forms/TextInput';
import Modal from 'Components/Common/Modal/Modal';
import PercentageHandler from 'Components/Common/PercentageHandler/PercentageHandler';
import SelectMultipleControlled from 'Components/Forms/SelectMultipleControlled';
import Loader from 'Components/Loader';
import useClipboardComponent from 'Hooks/useClipboardComponent';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ModelsIcon from 'Theme/icons/modelsIcon';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';
import * as yup from 'yup';

const UserPreferences = ({ store }) => {
  /** THEME */
  const { theme } = useThemeStore();

  /** PROFILE INFO */
  const {
    lname,
    fname,
    email,
    default_model,
    nickname_fpr,
    plan,
    api_key,
    multi_default_models,
    wordcap_limits,
  } = store.profile;
  const getImageProfile = () => store.profile.avatar.svg;

  /** STATES */
  const wordcapLimitsSection = useRef(null);
  const [models, setModels] = useState([]);
  const [loadingModels, setLoadingModels] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [firstname, setFirstName] = useState(fname);
  const [lastname, setLastName] = useState(lname);
  const [wordcapLimits, setWordcapLimits] = useState(
    getLimitValues(wordcap_limits)
  );
  const [isOpen, setIsOpen] = useState(false);
  const [loadingResetApiKey, setLoadingResetApiKey] = useState(false);

  const navigate = useNavigate();
  const { copy } = useClipboardComponent();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      api_key: api_key,
      email: email,
      fname: firstname,
      lname: lastname,
      default_model: [default_model],
      nickname_fpr: nickname_fpr
        ? `https://preview.straico.com/signup?fpr=${nickname_fpr}`
        : 'https://preview.straico.com/signup?fpr=',
      multi_default_models:
        multi_default_models.length > 0
          ? multi_default_models
          : [default_model],
      wordcap_limits: wordcap_limits,
    },
    resolver: yupResolver(schemaValidationSignUp),
  });

  // Helper function to check if a string is a valid URL
  function isURL(str) {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(str);
  }

  // Helper function to split the path and extract the 'fpr' value
  function getFprValueFromURL(url) {
    if (!isURL(url)) return null;
    const newUrl = new URL(url);
    const fpr = newUrl.searchParams.get('fpr');
    return fpr;
  }

  const viabilityMultiDefault = (multi_default_models) => {
    // Filter models based on IDs in multi_default_models
    const defaultModels = models.filter((model) =>
      multi_default_models.includes(model.id)
    );

    // Filter default models that are enabled
    const enabledModels = defaultModels.filter((model) => model.enabled);

    if (enabledModels.length > 0) {
      return enabledModels.map((model) => model.id);
    } else {
      // If no enabled default models, fallback based on conditions
      if (!default_model.enabled) {
        if (store.profile.plan === 'free') {
          return ['65f377dcaa4feb73a4ae62ac'];
        } else {
          return ['6620346cecf94d7b874019f2'];
        }
      } else {
        return [default_model.id];
      }
    }
  };

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    try {
      let fprValue = null;
      if (data.nickname_fpr) fprValue = getFprValueFromURL(data.nickname_fpr);

      const res = await store.api.post(`/user/update-user`, {
        lname: data.lname,
        fname: data.fname,
        default_model: data.default_model,
        multi_default_models: data.multi_default_models,
        nickname_fpr: fprValue,
        wordcap_limits: getValues('wordcap_limits'),
      });
      localStorage.removeItem('currentModel');

      if (res.status === 200) {
        localStorage.setItem('profile', JSON.stringify(res.data));
        setFirstName(res.data.fname);
        setLastName(res.data.lname);
        setSubmitLoading(false);
        store.profile.nickname_fpr = res.data.nickname_fpr;
        store.profile.wordcap_limits = res.data.wordcap_limits;
        toast.success('Profile updated');
      } else {
        toast.success('Updating error');
      }
    } catch (error) {
      toast.error(
        'Updating error, check that your referral link is valid or change it, to a unique one'
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCopy = (e) => {
    try {
      e.preventDefault();
      copy(store.profile.api_key);
      toast.success('API key copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  const resetApiKey = async () => {
    setLoadingResetApiKey(true);
    try {
      const res = await store.api.post(`/user/change-api-key`, {});
      if (res.status === 200) {
        store.profile.api_key = res.data.api_key;
        setValue('api_key', res.data.api_key);
        toast.success('API key updated');
      } else {
        toast.success('Updating error');
      }
    } catch (error) {
      toast.error('Updating error');
    } finally {
      setLoadingResetApiKey(false);
    }
  };

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const changeLimits = (newValues) => {
    const currentValues = getValues('wordcap_limits');
    setWordcapLimits(newValues);
    setValue('wordcap_limits', { ...currentValues, ...newValues });
  };

  /** USE EFFECTS */
  useEffect(() => {
    if (wordcapLimitsSection.current) {
      wordcapLimitsSection.current.id = 'wordcapLimitsSection';
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await store.api.get(`/model`);
        let availableModels = response.data.models.filter(
          (model) => model.enabled
        );
        let transformedModels;
        if (store.profile.plan == 'freeTos') {
          const res = await store.api.get('/user/constant', {
            params: {
              type: 'princing_plans',
            },
          });
          let freePlanModels = []; // Array to store models allowed for free users
          freePlanModels = res.data.rules[0].models;
          const modelsInFreePlan = []; // Array to store models available for free users
          const otherModels = []; // Array to store models not available for free users
          availableModels.forEach((model) => {
            if (freePlanModels.includes(model.model)) {
              modelsInFreePlan.push(model);
            } else {
              otherModels.push(model);
            }
          });
          transformedModels = modelsInFreePlan.map((model) => ({
            id: model._id,
            title: model.name,
          }));
        } else {
          transformedModels = response.data.models.map((model) => ({
            id: model._id,
            title: model.name,
          }));
        }
        control.multi_default_models =
          viabilityMultiDefault(multi_default_models);
        setModels(transformedModels);
        setLoadingModels(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const keys = Object.keys(errors);
      keys.forEach((key) => {
        toast.error(errors[key].message);
      });
    }
  }, [errors]);

  return (
    <div className="overflow-auto">
      <Container maxWidth="md" className="font-figtree">
        <BackButton
          text="Go back to user settings"
          onClick={() => navigate('/my-profile')}
        />
        <div
          className={cn('my-4 mb-10', {
            'text-white': theme === 'dark',
            'text-[#211E21]': theme !== 'dark',
          })}
        >
          <h1 className={cn('text-3xl font-bold')}>Your Preferences</h1>
          <p className="text-xl mt-2">
            Personalize your basic information and set up your preferred Straico
            experience.
          </p>
          {loadingModels ? (
            <div className="absolute inset-0 flex items-center justify-center h-full">
              <Loader active className="m-auto w-64 h-64 pr-10" />
            </div>
          ) : (
            <>
              <div className="mx-auto flex flex-row justify-between items-center mt-3">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <div className="w-16 h-16 rounded-full overflow-hidden">
                      {store.profile.avatar.svg ? (
                        <div className="avatar">
                          <div className="w-16 h-16 rounded-full overflow-hidden">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: getImageProfile(),
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <UserCircleIcon className="w-16 h-16transition text-cool-gray" />
                      )}
                    </div>

                    <div
                      className="absolute -bottom-2 -right-1 rounded-full bg-nue-blue w-6 h-6 flex items-center justify-center"
                      onClick={() => {
                        navigate('/my-avatar');
                      }}
                    >
                      <PencilSquareIcon className="cursor-pointer w-4 h-4 text-white bg-nue-blue rounded-full" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div
                      className={`text-lg font-bold uppercase ${
                        theme == 'dark'
                          ? 'dark:text-crystal-bell'
                          : 'text-raisin-black'
                      }`}
                    >{`${firstname} ${lastname}`}</div>
                    <div
                      className={`text-sm font-normal ${
                        theme == 'dark'
                          ? 'dark:text-crystal-bell'
                          : 'text-raisin-black'
                      }`}
                    >
                      {email}
                    </div>
                  </div>
                </div>
              </div>

              <form
                className="mx-auto flex flex-col justify-between space-y-6 my-7"
                onSubmit={handleSubmit(onSubmit)}
              >
                <section className="flex flex-col gap-6">
                  <p className="text-2xl font-bold capitalize">
                    Identity Essentials
                  </p>
                  <div className="flex flex-col gap-5 mb-4">
                    <div className="flex items-center">
                      <div className="w-1/5">
                        <LabelIcon icon={AtSymbolIcon} text="Email" />
                      </div>
                      <div className="w-4/5">
                        <TextInput
                          {...register('email')}
                          name="email"
                          type="email"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="flex items-center w-full">
                      <div className="w-1/5">
                        <LabelIcon
                          icon={IdentificationIcon}
                          text="True Identity"
                        />
                      </div>
                      <div className="flex items-center gap-4 w-4/5">
                        <div className="w-2/4">
                          <TextInput
                            {...register('fname')}
                            name="fname"
                            type="text"
                            placeholder="First Name"
                          />
                        </div>
                        <div className="w-2/4">
                          <TextInput
                            {...register('lname')}
                            name="lname"
                            type="text"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex items-center">
                      <div className="w-1/5">
                        <LabelIcon icon={BoltIcon} text="Hero alias" />
                      </div>
                      <div className="w-4/5 flex">
                        <TextInput placeholder="web devoloper" />
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          startIcon={<SparklesIcon className="size-5" />}
                          sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            textTransform: 'initial',
                            width: '100px',
                            padding: '2px',
                            borderRadius: '10px',
                            ml: 3,
                          }}
                        >
                          Use AI
                        </LoadingButton>
                      </div>
                    </div> */}
                  </div>
                </section>
                <section className="flex flex-col gap-6">
                  <p className="text-2xl font-bold capitalize">
                    Boost Your Influence
                  </p>
                  <p>
                    Save <strong>your personal referral link</strong> here, and
                    it will become a silent partner in every chat and template
                    you share.{' '}
                    <a
                      className="text-violet-blue cursor-pointer"
                      onClick={openModal}
                    >
                      Need help finding your referral link?
                    </a>
                  </p>

                  <div>
                    <div className="flex items-center">
                      <div className="w-1/5">
                        <LabelIcon icon={MegaphoneIcon} text="Referral Link" />
                      </div>
                      <div className="w-4/5">
                        <TextInput
                          name="nickname_fpr"
                          {...register('nickname_fpr')}
                        />
                      </div>
                    </div>
                  </div>

                  <p>
                    Not yet signed up? Embark on the path to monetizing your
                    influence by joining our{' '}
                    <a
                      className="text-violet-blue cursor-pointer"
                      href="https://straico.com/affiliate/"
                      target="__blank"
                    >
                      affiliate program.
                    </a>
                  </p>
                </section>
                <section className="flex flex-col gap-6">
                  <p className="text-2xl font-bold capitalize">
                    Personalize Your AI Interactions
                  </p>
                  <div className="flex flex-col gap-3">
                    <p className="font-bold capitalize">Default AI Models</p>
                    <p className="text-sm">
                      Your interactions with AI should be as individual as you
                      are. Select{' '}
                      <strong>
                        up to 4 default Large Language Models (LLMs)
                      </strong>
                      , crafting a personalized path of discovery and
                      interaction for all your chats.
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div className="w-1/5">
                      <LabelIcon icon={ModelsIcon} text="Choose LLMs" />
                    </div>
                    <div className="w-4/5">
                      <SelectMultipleControlled
                        fullWidth
                        control={control}
                        name="multi_default_models"
                        options={models}
                        disabled={plan === 'free' ? true : false}
                        selectProps={{
                          sx: {
                            '& .MuiSelect-select': {
                              borderRadius: '10px !important',
                              backgroundColor:
                                theme === 'light' ? 'white' : '#39383a',
                              fontSize: '14px',
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <p className="font-bold capitalize">Wordcap Limit</p>
                    <p className="text-sm">
                      Manage{' '}
                      <strong>
                        how many words are included in your requests
                      </strong>{' '}
                      to optimize your Straico coin usage. Recommended limit is
                      1,000 words per <strong>request</strong>. If you set the
                      limit to 0, the wordcap will be disabled, and word
                      capacity will then depend on the model's specific limits.
                    </p>
                    <div className="flex items-center w-full">
                      <div className="w-1/5">
                        <LabelIcon
                          icon={CalculatorIcon}
                          text="Words Per Request"
                        />
                      </div>
                      <div className="w-4/5">
                        <div className="w-fit">
                          <TextInput
                            {...register('wordcap_limits.words')}
                            placeholder="1000"
                            type="number"
                            name="wordcap_limits.words"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                    Use the slider below to configure the percentage
                    distribution for each component of the Wordcap Limit:
                  </p>
                  <PercentageHandler
                    attributes={wordcapLimits}
                    words={getValues('wordcap_limits').words}
                    onChange={changeLimits}
                  />
                </section>
                {store.profile.api_key && (
                  <section className="flex flex-col gap-6">
                    <p className="text-2xl font-bold capitalize">
                      Connect with Straico API
                    </p>

                    <p>
                      Easily enhance your projects with our{' '}
                      <strong>user-friendly API.</strong>
                      Supercharge your tools with AI capabilities for faster and
                      easier work. Visit our{' '}
                      <a
                        className="text-violet-blue cursor-pointer"
                        href="https://documenter.getpostman.com/view/5900072/2s9YyzddrR"
                        target="__blank"
                      >
                        API Documentation{' '}
                      </a>{' '}
                      for step-by-step guidance and support. Straico API
                      Documentation.
                    </p>
                    <div className="flex items-center">
                      <div className="w-1/5">
                        <LabelIcon icon={KeyIcon} text="API Key" />
                      </div>
                      <div className="w-4/5 flex">
                        <TextInput
                          {...register('api_key')}
                          name="api_key"
                          readonly
                          actionIcon={
                            <ClipboardIcon
                              className="cursor-pointer size-5"
                              onClick={handleCopy}
                            />
                          }
                        />
                        <LoadingButton
                          onClick={resetApiKey}
                          loading={loadingResetApiKey}
                          variant="contained"
                          color="primary"
                          startIcon={<RefreshOutlined className="size-4" />}
                          sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            textTransform: 'initial',
                            width: '130px',
                            padding: '2px',
                            borderRadius: '10px',
                            ml: 3,
                          }}
                        >
                          Reset Key
                        </LoadingButton>
                      </div>
                    </div>

                    <p>
                      Keep your API key secure—it's like a password. Lost it?
                      Contact us ASAP at{' '}
                      <a
                        className="text-violet-blue cursor-pointer"
                        href="mailto: hello@straico.com"
                        target="__blank"
                      >
                        hello@straico.com
                      </a>
                    </p>
                  </section>
                )}
                <div className="w-full pt-3">
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={submitLoading}
                    startIcon={<BookmarkIcon className="size-6" />}
                    sx={{
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '18px',
                      textTransform: 'initial',
                    }}
                  >
                    Save changes
                  </LoadingButton>
                </div>
              </form>
              <Modal
                open={isOpen}
                noIconTitle
                title="where to find your affiliate referral link?"
                onClose={closeModal}
              >
                <div className="flex flex-col gap-4 mt-[10px]">
                  <p className="text-raisin-black">
                    This feature is exclusively for members of{' '}
                    <strong>Straico's affiliate program.</strong> For details on
                    the benefits and enrollment process,{' '}
                    <a
                      className="text-violet-blue cursor-pointer"
                      href="https://straico.com/affiliate/"
                      target="__blank"
                    >
                      click here.
                    </a>
                  </p>
                  <img
                    onClick={() =>
                      window.open(
                        'https://straico.firstpromoter.com/',
                        '__blank'
                      )
                    }
                    src="/afiliate_images.png"
                    alt="affiliate"
                    className="cursor-pointer"
                    style={{
                      boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
                    }}
                  />
                  <p className="text-raisin-black">
                    With your referral link embedded, every chat and template
                    URL you share has the potential to boost your earnings.
                    Start sharing and watch your rewards grow! 🚀
                  </p>
                </div>
              </Modal>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default inject('store')(observer(UserPreferences));

const schemaValidationSignUp = yup.object({
  fname: yup
    .string()
    .required('First name is required')
    .matches(
      /^[A-Za-z0-9\s]+$/,
      'First name should only contain letters and numbers'
    ),
  lname: yup
    .string()
    .required('Last name is required')
    .matches(
      /^[A-Za-z0-9\s]+$/,
      'Last name should only contain letters and numbers'
    ),
  nickname_fpr: yup.string().url('Invalid URL').nullable(true),
});

const getLimitValues = (values) => {
  const temp = JSON.parse(JSON.stringify(values));
  delete temp.words;
  return temp;
};
