function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1.75V8.75C14 9.7165 13.2165 10.5 12.25 10.5H7.875L3.5 14V10.5H1.75C0.783502 10.5 0 9.7165 0 8.75V1.75C0 0.783502 0.783502 0 1.75 0H12.25C13.2165 0 14 0.783502 14 1.75ZM4.375 4.375H2.625V6.125H4.375V4.375ZM6.125 4.375H7.875V6.125H6.125V4.375ZM11.375 4.375H9.625V6.125H11.375V4.375Z"
        fill="inherit"
      />
    </svg>
  );
}

export default SvgComponent;
