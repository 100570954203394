import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
// Icons
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { CheckIcon, LinkIcon } from '@heroicons/react/24/solid';
import ShareIcon from '../../../../Theme/icons/titleHeaderIcons/shareIcon';
// Hooks
import useModal from 'Hooks/useModal';
// config
import config from 'config';
// Components
import Modal from 'Components/Common/Modal/NewModal';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import useClipboardComponent from 'Hooks/useClipboardComponent';
import ShareInput from 'Components/Common/ShareInput/ShareInput';
import useTreeDNDChatStore from 'Components/Chat/TreeDNDChat/Store';
import useThemeStore from 'Theme/store';
import ChatMessage from 'Components/Common/Markdown/ChatMessage';
import { formatedDate } from '../../utils/format';
import { IconButton, Tooltip } from '@mui/material';
import { TABS } from 'Components/Chat/store';

const ShareChat = inject('store')(
  observer(({ store, chat, capabilities, avatar }) => {
    const { copy } = useClipboardComponent();
    const { isOpen, closeModal, toggleModal } = useModal();
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(chat.title);
    const [showEditDialog, setShowEditDialog] = useState(false);
    //
    useEffect(() => {
      setTitle(chat.title);
    }, [chat]);
    const [shareLink, setShareLink] = useState(null);
    useEffect(() => {
      setShareLink(null);
    }, [isOpen]);
    // modify share state chat
    const onShare = async () => {
      setLoading(true);
      try {
        const lastMessage = Array.isArray(chat.messages[0])
          ? chat.messages[0].filter((m) => m.selected == true)
          : [chat.messages[0]];
        const res = await store.api.post('/shareChats', {
          title: title === '' ? chat.title : title,
          chat_id: chat._id,
          message_id: lastMessage[0]._id,
        });
        const data = await res.data;
        let link = `${config.baseFrontUrl}/share/chat/${data.share_id}`;
        if (store.profile.nickname_fpr)
          link = `${link}?fpr=${store.profile.nickname_fpr}`;
        setShareLink(link);
        copy(link);
        toast.success('Chat shared successfully');
      } catch (err) {
        toast.error('Error sharing chat');
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    // handle edit title
    const toggleTittle = () => {
      setShowEditDialog(!showEditDialog);
    };
    //handle change title
    const handleChangeTitle = (e) => {
      setTitle(e.target.value);
    };

    const {
      setOpenShareChatModal,
      openShareChatModal: openShareChatModalTree,
    } = useTreeDNDChatStore();

    useEffect(() => {
      if (openShareChatModalTree) {
        toggleModal();
        setOpenShareChatModal(false);
      }
    }, [openShareChatModalTree]);

    const { theme } = useThemeStore();

    // return
    return (
      <>
        <Tooltip title="Share" arrow>
          <IconButton onClick={toggleModal}>
            <ShareIcon
              className={'h-5 w-5 text-violet-blue dark:text-stargate-shimmer'}
            />
          </IconButton>
        </Tooltip>
        <Modal
          open={isOpen}
          onClose={closeModal}
          noMaxWidth
          maxHeight
          noPaddingX
        >
          <div className="flex-1 flex flex-col font-figtree text-base text-black dark:text-crystal-bell leading-6 font-normal overflow-auto px-[30px]">
            <div className="w-full flex justify-start">
              <div className="text-raisin-black  dark:text-crystal-bell text-[28px] leading-7 font-bold mb-5">
                Share chat
              </div>
            </div>
            <div className="mb-5 text-raisin-black dark:text-crystal-bell">
              Anyone with the URL will be able to see the chat up to this point.
              Further messages you send after creating the link will remain
              private.
            </div>
            <div className="flex text-cool-gray bg-platinum h-7 rounded-lg">
              <div className="w-full flex mx-4 items-center">
                <div className="flex-grow truncate w-40">
                  {!showEditDialog ? (
                    <p className={`text-base font-medium`}>{title}</p>
                  ) : (
                    <input
                      className="border border-cool-gray w-full rounded-lg bg-transparent"
                      value={title}
                      onChange={handleChangeTitle}
                    />
                  )}
                </div>
              </div>
              <div
                className="w-10 p-1 flex items-center justify-center hover:bg-ghost-white cursor-pointer"
                onClick={toggleTittle}
              >
                {!showEditDialog ? (
                  <PencilSquareIcon className={'h-5 w-5'} />
                ) : (
                  <CheckIcon className={'h-5 w-5'} />
                )}
              </div>
            </div>
            <div
              className={`flex-1 overflow-auto overflow-rtl overflow-x-hidden ${
                theme == 'dark' ? 'overflow-rtl-dark' : 'overflow-rtl-light'
              } ${
                theme == 'dark' ? 'bg-night-black' : 'bg-white'
              } flex flex-col-reverse my-3 rounded-[20px] shadow-[4px_4px_14px_0px_rgba(0,0,0,0.25)] p-1`}
            >
              {chat.messages.map((message, index) => (
                <ChatMessage
                  key={index}
                  item={message}
                  index={index}
                  formatedDate={formatedDate}
                  readOnlyMessage={true}
                  chatId={chat._id}
                  avatar={store.profile.avatar.svg}
                  capabilities={capabilities}
                  fixRenderType={TABS}
                />
              ))}
            </div>
            {shareLink ? (
              <ShareInput shareLink={shareLink} />
            ) : (
              <div className="flex justify-center">
                <LoadingButton
                  loading={loading}
                  onClick={onShare}
                  variant="contained"
                  color="primary"
                  sx={{
                    color: 'white',
                    fontWeight: 600,
                    fontSize: '18px',
                    lineHeight: '24px',
                    fontFamily: 'Figtree',
                    borderRadius: '12px',
                    textTransform: 'none',
                    padding: '8px 16px',
                  }}
                  startIcon={<LinkIcon className="h-5 w-5" />}
                >
                  Copy Link
                </LoadingButton>
              </div>
            )}
            <div className="w-3/4 self-center text-center mt-7 text-raisin-black dark:text-crystal-bell">
              Boost sign-ups and earn with every share! Join{' '}
              <strong>Straico's affiliate program</strong> and convert your
              shares into a revenue stream. 💰{' '}
              <a
                className="text-violet-blue cursor-pointer"
                href="https://straico.com/affiliate/"
                target="__blank"
              >
                Start here!
              </a>
            </div>
          </div>
        </Modal>
      </>
    );
  })
);

export default ShareChat;
