function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.895431 0 2 0H6L8 2H12C13.1046 2 14 2.89543 14 4V5H6C4.34315 5 3 6.34315 3 8V9.5C3 10.3284 2.32843 11 1.5 11C0.671573 11 0 10.3284 0 9.49998V2Z"
        fill="inherit"
      />
      <path
        d="M4 8C4 6.89543 4.89543 6 6 6H14C15.1046 6 16 6.89543 16 8V10C16 11.1046 15.1046 12 14 12H0H2C3.10457 12 4 11.1046 4 10V8Z"
        fill="inherit"
      />
    </svg>
  );
}

export default SvgComponent;
