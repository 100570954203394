function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M3 5.64421H13M3 5.64421C1.89543 5.64421 1 4.94776 1 4.08865V2.53309C1 1.67398 1.89543 0.977539 3 0.977539H13C14.1046 0.977539 15 1.67399 15 2.53309V4.08865C15 4.94776 14.1046 5.64421 13 5.64421M3 5.64421C1.89543 5.64421 1 6.34065 1 7.19976V8.75532C1 9.61443 1.89543 10.3109 3 10.3109M13 5.64421C14.1046 5.64421 15 6.34065 15 7.19976V8.75532C15 9.61443 14.1046 10.3109 13 10.3109M3 10.3109H13M3 10.3109C1.89543 10.3109 1 11.0073 1 11.8664V13.422C1 14.2811 1.89543 14.9775 3 14.9775H13C14.1046 14.9775 15 14.2811 15 13.422V11.8664C15 11.0073 14.1046 10.3109 13 10.3109"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
