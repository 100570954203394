/* eslint-disable react/display-name */
import { memo, useState, useRef, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

export const MemoizedOriginalPrompt = memo(
  ({ originalPrompt, theme, optimizing }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [showShimmer, setShowShimmer] = useState(optimizing);
    const promptRef = useRef(null);

    const toggleExpand = () => {
      setIsExpanded((prev) => !prev);
    };

    useEffect(() => {
      if (promptRef.current) {
        setIsOverflowing(
          promptRef.current.scrollWidth > promptRef.current.clientWidth
        );
      }
    }, [originalPrompt]);

    useEffect(() => {
      if (optimizing) {
        setShowShimmer(true);
        const timer = setTimeout(() => {
          setShowShimmer(false);
        }, 2000);

        return () => clearTimeout(timer);
      } else {
        setShowShimmer(false);
      }
    }, [optimizing]);

    if (!originalPrompt) return null;

    return (
      <div
        className={`p-2 rounded-md mb-2 mx-auto w-full text-left relative ${
          theme === 'dark'
            ? 'text-gray-100 text-opacity-70'
            : 'text-gray-600 text-opacity-70'
        } ${
          showShimmer
            ? theme === 'dark'
              ? 'shimmer-effect-dark'
              : 'shimmer-effect'
            : ''
        }`}
        style={{
          width: '100%',
          paddingRight: '4%',
          backgroundColor: theme === 'dark' ? '#39383A' : '#EDEDF2',
          color: theme === 'dark' ? '#787878' : '#9091A4',
          fontSize: '15px',
        }}
      >
        <span
          ref={promptRef}
          className={`block overflow-hidden ${
            isExpanded ? 'whitespace-normal' : 'whitespace-nowrap'
          }`}
          style={{ textOverflow: isExpanded ? 'clip' : 'ellipsis' }}
        >
          <span
            style={{
              fontStyle: 'italic',
              fontWeight: 600,
              color: 'inherit',
            }}
          >
            Original Prompt:
          </span>{' '}
          {originalPrompt}
        </span>

        {isOverflowing && (
          <ChevronDownIcon
            onClick={toggleExpand}
            className={`w-5 h-5 absolute cursor-pointer transition-transform ${
              isExpanded ? 'rotate-180' : ''
            } ${
              isExpanded
                ? 'top-auto bottom-2 right-1/2 transform translate-x-1/2'
                : 'top-1/2 right-2 transform -translate-y-1/2'
            }`}
          />
        )}
      </div>
    );
  }
);
