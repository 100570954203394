import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@mui/material';
import useClipboardComponent from 'Hooks/useClipboardComponent';
import { memo } from 'react';
import toast from 'react-hot-toast';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { cn } from 'utils/styles';

const CodeBlock = memo(({ language, value, className, ...props }) => {
  const { copy } = useClipboardComponent();
  return (
    <div
      className={cn('relative bg-umbra w-full overflow-hidden', className)}
      {...props}
    >
      <div className="flex items-center justify-between w-full px-6 py-2 pr-4 bg-night-black text-white">
        <span className="text-xs lowercase">{language}</span>
      </div>
      <div className="overflow-hidden ">
        <SyntaxHighlighter
          language={language === 'markdown' ? '' : language}
          style={coldarkDark}
          PreTag="div"
          showLineNumbers={false}
          customStyle={{
            margin: 0,
            background: 'transparent',
          }}
          codeTagProps={{
            style: {
              fontSize: '0.9rem',
              fontFamily: 'var(--font-mono)',
            },
          }}
        >
          {value}
        </SyntaxHighlighter>
      </div>
      <div className="flex justify-start p-2 absolute -top-2 right-1">
        <Tooltip title="Copy text to clipboard" placement="top">
          <div
            className="p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative group flex flex-col items-center group text-white"
            onClick={() => {
              try {
                copy(value);
                toast.success('Copied to clipboard');
              } catch (error) {
                toast.error('Error copying to clipboard');
              }
            }}
          >
            <DocumentDuplicateIcon className="w-5 h-5" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
});

CodeBlock.displayName = 'CodeBlock';

export { CodeBlock };
