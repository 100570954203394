import { useState, useEffect } from 'react';
// Material UI Components
import {
  Button,
  SwipeableDrawer,
  Typography,
  Box,
  TextField,
  useMediaQuery,
} from '@mui/material';
// Icons
import {
  PencilSquareIcon,
  ArrowRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
// Stores
import useCreateTemplatesStore from './Store';
import useThemeStore from 'Theme/store';
import SmartPromptTemplate from './SmartPromptTemplate';
// Components
import ModalConfirmClose from './Components/ModalConfirmClose/ModalConfirmClose';
// Utilities
import { cn } from 'utils/styles';
import Loader from '../../Components/Loader';
import toast from 'react-hot-toast';
import { useTheme } from '@mui/material/styles';

const CreateSmartTemplate = ({ store }) => {
  // Stores and Theme
  const {
    setSelectedComponent,
    setOpenModal,
    openModal,
    selectedComponent,
    modalConfirmClose,
    setModalConfirmClose,
  } = useCreateTemplatesStore();
  const { theme } = useThemeStore();

  // State to control drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [promptInput, setPromptInput] = useState('');
  const [templateGenerated, setTemplateGenerated] = useState(false);

  // Handlers
  const handleSelectTemplate = (component) => {
    setSelectedComponent(component);
    setOpenModal(true);
  };

  const handleCancelConfirmClose = () => setModalConfirmClose(false);

  const handleClose = () => {
    setOpenModal(false);
    setSelectedComponent(null);
  };

  const handleCloseConfirmClose = () => {
    setModalConfirmClose(false);
    setOpenModal(false);
    setSelectedComponent(null);
  };

  // Update drawerOpen based on selectedComponent and openModal
  useEffect(() => {
    setDrawerOpen(selectedComponent === 'smartTemplate' && openModal);
  }, [selectedComponent, openModal]);

  useEffect(() => {
    if (drawerOpen) {
      setPromptInput('');
    }
  }, [drawerOpen]);

  const [loading, setLoading] = useState(false);
  const [templateData, setTemplateData] = useState(null);

  const suggestTemplate = async () => {
    setLoading(true);
    if (!promptInput || promptInput.trim() === '') {
      toast('Enter a prompt to create your template!', {
        icon: '✏️',
      });
      setLoading(false);
      return null;
    }

    try {
      const response = await store.api.post(`/ai/chat/smart-template`, {
        prompt: promptInput,
        coins: 10,
      });

      if (response.data.success) {
        const {
          templateName,
          description,
          optimalLLMModel,
          suggestedPrompt,
          variables,
          categoricalVariables,
          icon,
        } = response.data;

        setTemplateData({
          templateName,
          description,
          optimalLLMModel,
          suggestedPrompt,
          variables,
          categoricalVariables,
          icon,
        });

        setLoading(false);
        setOpenModal(false);
        setSelectedComponent(null);
        setTemplateGenerated(true);
        return response.data;
      } else {
        setLoading(false);
        return null;
      }
    } catch (error) {
      console.error('Error suggesting template:', error);
      setLoading(false);
      return null;
    }
  };

  const themeMui = useTheme();
  const isMobile = useMediaQuery(themeMui.breakpoints.down('sm'));

  return (
    <>
      <div
        className={cn('', {
          'text-white': theme === 'dark',
          'text-[#211E21]': theme !== 'dark',
        })}
      >
        <SwipeableDrawer
          anchor={isMobile ? 'bottom' : 'right'}
          open={drawerOpen}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: isMobile ? '100%' : '550px',
              height: isMobile ? '100%' : '70%',
              padding: isMobile ? '16px' : '24px',
              borderRadius: isMobile ? '20px 20px 0 0' : '20px 0 0 20px',
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'Figtree, sans-serif',
              bgcolor: theme === 'dark' ? '#252425' : '#F7F7F8',
              ...(isMobile
                ? {}
                : {
                    position: 'fixed',
                    top: '15%',
                    right: 0,
                  }),
            },
            elevation: 0,
          }}
        >
          {loading ? (
            <div className="flex flex-col items-center justify-center h-full w-full">
              <Loader className="h-8 mb-2" />
              <span
                className="text-sm font-semibold"
                style={{
                  color: theme === 'dark' ? '#464BBA' : '#464BBA',
                }}
              >
                Generating your template...
              </span>
            </div>
          ) : (
            <>
              <Box position="absolute" top={16} right={16}>
                <XMarkIcon
                  className={`h-6 w-6 cursor-pointer ${
                    theme === 'dark' ? 'text-white' : 'text-gray-500'
                  }`}
                  onClick={handleClose}
                />
              </Box>
              <Box textAlign="left" mb={2} width="100%" mt={2}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  sx={{
                    textAlign: 'left',
                    color: theme === 'dark' ? 'white' : 'black',
                    fontSize: '1.8rem',
                    mb: 4,
                    fontFamily: 'Figtree, sans-serif',
                  }}
                >
                  New smart prompt template
                </Typography>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    textAlign: 'left',
                    color: theme === 'dark' ? '#D4D5E6' : 'black',
                    fontSize: '1rem',
                    mb: 2,
                    fontFamily: 'Figtree, sans-serif',
                  }}
                >
                  Description
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    marginBottom: '4px',
                    color: theme === 'dark' ? '#cfcccc' : '#6e6e6e',
                    fontFamily: 'Figtree, sans-serif',
                  }}
                >
                  Describe what you want to achieve, and our smart creator will
                  generate a template tailored to your needs.
                </Typography>
                <TextField
                  placeholder="Make a recipe using the ingredients, time and skill level I have"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  value={promptInput}
                  onChange={(e) => setPromptInput(e.target.value)}
                  sx={{
                    marginTop: '16px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      resize: 'vertical',
                      overflow: 'auto',
                      bgcolor: theme === 'dark' ? '#333333' : '#FFFFFF',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme === 'dark' ? '#787878' : '#D0D0D8',
                    },
                    '& .MuiInputBase-input': {
                      fontSize: '0.875rem',
                      color: theme === 'dark' ? '#ffffff' : '#000000',
                      fontFamily: 'Figtree, sans-serif',
                    },
                  }}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                gap={2}
                width="100%"
                mt="auto"
              >
                <Button
                  onClick={() => handleSelectTemplate('regularTemplate')}
                  variant="outlined"
                  fullWidth
                  startIcon={
                    <PencilSquareIcon style={{ width: 20, height: 20 }} />
                  }
                  sx={{
                    borderRadius: '14px',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    color: theme === 'dark' ? '#7F82C3' : '#9091A4',
                    backgroundColor: theme === 'dark' ? '#3B3B45' : '#E1E1E5',
                    borderColor: 'transparent',
                    '&:hover': {
                      borderColor: 'transparent',
                    },
                  }}
                >
                  Regular Creator
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <ArrowRightIcon style={{ width: 20, height: 20 }} />
                  }
                  sx={{
                    borderRadius: '14px',
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                  onClick={suggestTemplate}
                >
                  Generate
                </Button>
              </Box>
            </>
          )}
        </SwipeableDrawer>

        {/* Confirmation Close Modal */}
        <ModalConfirmClose
          open={modalConfirmClose}
          onCancel={handleCancelConfirmClose}
          onClose={handleCloseConfirmClose}
        />
      </div>
      {templateGenerated && (
        <SmartPromptTemplate
          onClose={() => setTemplateGenerated(false)}
          templateData={templateData}
          store={store}
        />
      )}
    </>
  );
};

export default CreateSmartTemplate;
