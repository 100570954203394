function SvgComponent({ fillColor = '#C48C7A', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill={fillColor} />
      <path
        d="M5 6.57269V8.14537H7.71366H10.4273V13.5727V19H14.7137H19V17.4273V15.8546H16.2863H13.5727V10.4273V5H9.28634H5V6.57269Z"
        fill="white"
      />
      <path
        d="M15.8547 6.57258V8.14526H17.4274H19.0001V6.57258V4.99989H17.4274H15.8547V6.57258Z"
        fill="white"
      />
      <path
        d="M5 17.4273V19H6.57269H8.14537V17.4273V15.8546H6.57269H5V17.4273Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
