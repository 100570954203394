function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="currentColor"
      {...props}
    >
      <path
        d="M0 1.75C0 0.783502 0.783502 0 1.75 0H6.125L7.875 1.75H12.25C13.2165 1.75 14 2.5335 14 3.5V8.75C14 9.7165 13.2165 10.5 12.25 10.5H1.75C0.783502 10.5 0 9.7165 0 8.75V1.75Z"
        fill="inherit"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
