import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import ChatMessage from 'Components/Common/Markdown/ChatMessage';
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import useFavoriteMessagesStore from './store';
import { DialogContent, DialogTitle } from '@mui/material';
import { cn } from 'utils/styles';
import useThemeStore from 'Theme/store';
import { TABS } from 'Components/Chat/store';
import { formatedDate } from '../../../../Features/Core/utils/format.js';
import { InView } from 'react-intersection-observer';
import toast from 'react-hot-toast';
import { ArchiveBoxXMarkIcon, StarIcon } from '@heroicons/react/24/solid';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline';

const FavoriteMessages = ({ store, onSelectChat, avatar, onLoading }) => {
  const [chatsWithFavorites, setChatsWithFavorites] = useState([]);

  const { openFavoriteMessages, setOpenFavoriteMessages } =
    useFavoriteMessagesStore();

  const handleClose = () => setOpenFavoriteMessages(false);

  const { theme } = useThemeStore();

  const [limit, setLimit] = useState(4);

  const [pageData, setPageData] = useState({
    totalMessages: 0,
    totalPages: 1,
    currentPage: 1,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // When opened, fetch favorite messages
    if (openFavoriteMessages) {
      fetchFavoriteMessages();
    } else {
      // When closed, reset the state
      setChatsWithFavorites([]);
      setPageData({
        totalMessages: 0,
        totalPages: 1,
        currentPage: 1,
      });
      setLimit(4);
    }
  }, [openFavoriteMessages]);

  useEffect(() => {
    // When limit changes, fetch favorite messages next page
    if (openFavoriteMessages) {
      fetchFavoriteMessages();
    }
  }, [limit]);

  const onChangeLimit = (inView) => {
    if (inView && pageData.currentPage < pageData.totalPages) {
      setLimit((prev) => prev + limit);
    }
  };

  const fetchFavoriteMessages = async () => {
    setLoading(true);
    try {
      const response = await store.api.get('chat/many/favorite-messages', {
        params: { limit: limit, page: pageData.currentPage },
      });
      if (response.data.success) {
        setChatsWithFavorites(response.data.chats);
        setPageData({
          totalMessages: response.data.totalMessages,
          totalPages: response.data.totalPages,
          currentPage: response.data.currentPage,
        });
      }
    } catch (error) {
      toast.error('Error loading favorite messages');
    } finally {
      setLoading(false);
    }
  };

  const isUser = (item) => {
    return !Array.isArray(item) && item.data.role === 'user';
  };

  const handleMessageClick = (message, chatId) => {
    setOpenFavoriteMessages(false);
    const user = isUser(message);
    let identifier;

    if (user) {
      identifier = message.date;
    } else {
      if (Array.isArray(message)) {
        const selected = message.filter((m) => m.selected == true);
        identifier = selected.length > 0 ? selected[0]._id : message[0]._id;
      } else {
        identifier = message._id;
      }
    }

    onSelectChat({ _id: chatId }, identifier, user);
  };

  return (
    <>
      <CommonModal
        withCloseButton
        open={openFavoriteMessages}
        handleClose={handleClose}
        scroll="paper"
        withoutContainer
        maxWidth="md"
        fullWidth
        withoutPadding
        sx={{
          height: { md: 'calc(100% - 8px)', xs: '100dvh' },
        }}
      >
        <DialogTitle
          className={cn('flex flex-col w-full p-9 gap-6', {
            'bg-lead': theme === 'dark',
          })}
        >
          <h1
            className={cn(
              'capitalize text-xl md:text-3xl font-bold font-figtree',
              {
                'text-crystal-bell': theme === 'dark',
                'text-raisin-black': theme !== 'dark',
              }
            )}
          >
            My Favorite Messages{' '}
            {pageData.totalMessages > 0 && (
              <span
                className={cn(
                  'inline-flex items-center rounded-full px-2 py-0.5 ml-1 text-base',
                  {
                    'bg-yellow-500 text-yellow-900': theme === 'dark',
                    'bg-seasalt text-raisin-black': theme !== 'dark',
                  }
                )}
              >
                <StarIcon className="w-5 h-5 inline-block align-middle mr-1" />
                <span style={{ verticalAlign: 'middle' }}>
                  {pageData.totalMessages}
                </span>
              </span>
            )}
          </h1>
        </DialogTitle>
        <DialogContent
          className={cn(
            'flex-1 overflow-rtl overflow-x-hidden flex-col flex w-full px-9 py-6',
            {
              'bg-white overflow-rtl-dark': theme !== 'dark',
              'bg-umbra overflow-rtl-light': theme === 'dark',
            }
          )}
        >
          <>
            {!loading && chatsWithFavorites?.length === 0 && (
              <div className="flex items-center justify-center w-full h-full">
                <div className="flex justify-center items-center flex-col">
                  <ArchiveBoxXMarkIcon className="w-12 h-12" />
                  <p
                    className={cn('text-base font-bold font-figtree', {
                      'text-crystal-bell': theme === 'dark',
                      'text-raisin-black': theme !== 'dark',
                    })}
                  >
                    No favorites yet! Click the star icon on any message to save
                    it here for quick access
                  </p>
                </div>
              </div>
            )}

            {chatsWithFavorites.map((chat, index) => (
              <div key={index}>
                {/* CHAT TITLES */}
                <div className="flex items-center my-4">
                  <div
                    className={cn(
                      'flex items-center gap-2 md:text-base text-sm font-bold',
                      {
                        'text-crystal-bell': theme === 'dark',
                        'text-raisin-black': theme !== 'dark',
                      }
                    )}
                  >
                    <ChatBubbleLeftEllipsisIcon className="w-4 h-4" />
                    <h2 className="font-figtree">{chat.title}</h2>
                  </div>
                </div>

                {/* MESSAGES */}
                {chat.messages.map((message, msgIndex) => (
                  <div
                    key={msgIndex}
                    className="cursor-pointer"
                    onClick={() => handleMessageClick(message, chat._id)}
                  >
                    <ChatMessage
                      item={message}
                      formatedDate={formatedDate}
                      readOnlyMessage={true}
                      chatId={chat._id}
                      avatar={avatar}
                      onLoading={onLoading}
                      fixRenderType={TABS}
                    />
                  </div>
                ))}
              </div>
            ))}

            {loading && (
              <>
                {Array.from({ length: 6 }).map((_, index) => (
                  <MessageSkeleton
                    key={index}
                    classNameContainer="!m-4 !h-[170px] !min-w-[300px]"
                  />
                ))}
              </>
            )}

            {chatsWithFavorites.length > 0 && (
              <div className="flex items-center justify-center w-full">
                <InView onChange={onChangeLimit}>
                  <div className="flex items-center justify-center">.</div>
                </InView>
              </div>
            )}
          </>
        </DialogContent>
      </CommonModal>
    </>
  );
};

const MessageSkeleton = ({ classNameContainer }) => {
  return (
    <div
      className={`
        animate-pulse /* Adds a pulsing effect */
        ${useThemeStore().theme === 'dark' ? 'bg-graphite-900' : 'bg-white'}
        rounded-lg px-4 py-5 min-w-[308px] border border-snow-900 dark:border-snow-100 m-4 ${classNameContainer}
      `}
    >
      <div className="flex flex-col space-y-2">
        {/* First bar */}
        <div className="h-4 bg-snow-600 rounded !max-w-[50px]"></div>

        {/* Circle and second bar */}
        <div className="flex items-center space-x-2">
          <div className="w-8 h-8">
            <div className="rounded-full bg-snow-600 w-8 h-8"></div>
          </div>
          <div className="h-4 bg-snow-600 rounded w-1/2"></div>
        </div>

        {/* Third bar */}
        <div className="h-4 bg-snow-600 rounded w-full mt-2"></div>
      </div>
    </div>
  );
};

export default inject('store')(observer(FavoriteMessages));
